// eslint-disable-next-line
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { Result } from 'antd';

class Error extends React.Component<RouteComponentProps<{ status?: '500' | '403' | '404' }, {}, { message: string }>> {
    render() {
        if (this.props.match.params.status === '403') {
            return (
                <div>
                    <Result
                        status="403"
                        title="Ci dispiace, non riusciamo a trovare il documento che stai cercando di firmare"
                        subTitle={this.props.location.state?.message}
                    />
                </div>
            );
        }
        if (this.props.match.params.status === '404') {
            return (
                <div>
                    <Result
                        status="404"
                        title="Ci dispiace, ma il documento che stai cercando di firmare non è più disponibile per la firma"
                        subTitle={this.props.location.state?.message}
                    />
                </div>
            );
        }
        return (
            <div>
                <Result
                    status={this.props.match.params.status ?? '500'}
                    title="Ops... qualcosa non ha funzionato"
                    subTitle={this.props.location.state?.message}
                />
            </div>
        );
    }
}

export default Error;