// eslint-disable-next-line
import React from "react";
import { Carousel } from 'antd';

class Preview extends React.Component<{ pages: string[] }> {
    onChange() {
    }
    render() {
        const contentStyle = {
        };
        const items = []
        for (let i = 0; i < this.props.pages.length; i++) {
            items.push(<div key={i} className="image">
                <img style={contentStyle} src={`data:image/png;base64,${this.props.pages[i]}`} alt={`img_${i}`} />
            </div>)
        }
        return (
            <div>
                <Carousel afterChange={this.onChange} dotPosition="bottom" className="carousel">
                    {items}
                </Carousel>
            </div >
        );
    }
};

export default Preview;
