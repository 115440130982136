// eslint-disable-next-line
import React, { FC, useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Button,
  DatePicker,
  Switch,
  Row,
  Col,
  Upload,
  Divider,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import countries from "i18n-iso-countries";
import _ from "lodash";
import axios from "axios";
import ISigner from "./ISigner";
import moment from "moment";
import { RcFile } from "antd/lib/upload";

const { Option } = Select;

countries.registerLocale(require("i18n-iso-countries/langs/it.json"));
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
const documentTypes = [
  "Carta d'identità",
  "Patente di guida",
  "Passaporto",
  "Porto d'armi",
  "Tessera di riconoscimento postale",
  "Tessera rilasciata da PA",
];
const SignerForm: FC<{
  signer: ISigner;
  input: (signer: Partial<ISigner>, iAmSigner: boolean) => void;
  graphometric: boolean;
  fea: boolean;
  enableSignerReassign: boolean;
}> = (props: {
  signer: ISigner;
  input: (signer: Partial<ISigner>, iAmSigner: boolean) => void;
  graphometric: boolean;
  fea: boolean;
  enableSignerReassign: boolean;
}) => {
  const [form] = Form.useForm();
  const [loadingState, setLoadingState] = useState<boolean>();
  const [hasSignature, setHasSignature] = useState<boolean>(true);
  const [iAmSigner, setIAmSigner] = useState<boolean>(props.graphometric);
  const fc = props.signer.fiscalCode;
  useEffect(() => {
    retrieveNeedFeaDocument(fc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onFinish = (values: Partial<ISigner>) => {
    props.input(values, iAmSigner);
  };
  const handleFiscalChangeSearch = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleFiscalCodeSearchDebounce(e.currentTarget.value);
  };
  const handleFiscalCodeSearchDebounce = _.debounce(
    (v) => handleFiscalCodeSearch(v),
    500
  );
  
  const retrieveNeedFeaDocument = async (v: string) => {
    if (props.fea && v && v.length > 10) {
      const res = await (async () => {
        try {
          const res = await axios.post("DigitalSign/HasSignatures", JSON.stringify(v), {
            headers: {
              "content-type": "application/json",
            },
          });
          if (res.status === 200) {
            return res.data;
          }
        } finally {
        }
        return false;
      })();
      setHasSignature(res);
    }
  }
  const handleFiscalCodeSearch = async (v: string) => {
    if (v && v.length > 10 && !loadingState) {
      try {
        setLoadingState(true);
        retrieveNeedFeaDocument(v);
        const res = await axios.post("DigitalSign/Search", JSON.stringify(v), {
          headers: {
            "content-type": "application/json",
          },
        });
        if (res.status === 200 && res.data) {
          if (res.data.birthDate) {
            res.data.birthDate = moment(res.data.birthDate);
          }
          if (res.data.documentEndDate != null) {
            res.data.documentEndDate = moment(res.data.documentEndDate);
          }
          form.setFieldsValue(res.data);
        }
      } finally {
        setLoadingState(false);
      }
    }
  };
  const handleBeforeUpload = async (file: RcFile, fileList: RcFile[]) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        const base64 = e.target?.result;
        form.setFieldValue("document", base64);
      };
      reader.readAsDataURL(file);
    }
    return false;
  };

  const signer: any = props.signer;
  if (signer.birthDate != null) {
    signer.birthDate = moment(signer.birthDate);
  }
  if (signer.documentEndDate != null) {
    signer.documentEndDate = moment(signer.documentEndDate);
  }
  return (
    <Row justify="center">
      <Col xs={24} lg={22} xl={16} xxl={16}>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          initialValues={signer}
          scrollToFirstError
          className="m-l m-r"
          autoComplete="off"
        >
          <Form.Item
            name="fiscalCode"
            label="Codice Fiscale"
            rules={[
              {
                required: true,
                message: "Specificare il Cognome del firmatario",
              },
              {
                max: 20,
                min: 10,
                message: "Inserire un Codice Fiscale valido",
              },
            ]}
          >
            <Input.Search
              onSearch={handleFiscalCodeSearch}
              loading={loadingState}
              onChange={handleFiscalChangeSearch}
            />
          </Form.Item>
          <Form.Item
            name="firstName"
            label="Nome"
            rules={[
              {
                required: true,
                min: 2,
                message: "Specificare il Nome del firmatario",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Cognome"
            rules={[
              {
                required: true,
                min: 2,
                message: "Specificare il Cognome del firmatario",
              },
            ]}
          >
            <Input disabled={!props.enableSignerReassign} />
          </Form.Item>
          <Form.Item
            name="address"
            label="Indirizzo"
            rules={[
              {
                required: true,
                min: 2,
                message: `Specificare l'indirizzo del firmatario`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Città"
            name="city"
            rules={[
              {
                required: true,
                message: `Specificare la città del firmatario`,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cap"
            label="CAP"
            rules={[
              {
                required: true,
                message: `Specificare il CAP del firmatario`,
              },
            ]}
          >
            <Input style={{ width: "20%" }} placeholder="Cap" />
          </Form.Item>
          <Form.Item
            name="country"
            label="Nazione"
            rules={[
              {
                required: true,
                min: 2,
                message: `Specificare la nazione del firmatario`,
              },
            ]}
          >
            <Select placeholder="Seleziona una nazione" showSearch>
              (
              {_.sortBy(
                Object.entries(countries.getNames("it")),
                (c) => c[1]
              ).map((c) => {
                return <Option value={c[0]}>{c[1]}</Option>;
              })}
              )
            </Select>
          </Form.Item>
          <Form.Item label="Data di nascita" required>
            <Input.Group compact>
              <Form.Item
                name="birthDate"
                className="m-b-0"
                rules={[
                  {
                    required: true,
                    message: "Specificare la data di nascita del firmatario",
                  },
                ]}
                style={{ width: "40%" }}
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" />
              </Form.Item>

              <Form.Item
                name="sex"
                rules={[
                  {
                    required: true,
                    message: "Specificare il genere del firmatario",
                  },
                ]}
                style={{ width: "60%" }}
                className="p-l m-b-0"
              >
                <Select
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Sesso"
                >
                  <Option value="M">Maschio</Option>
                  <Option value="F">Femmina</Option>
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
          <Form.Item
            name="birthCity"
            label="Luogo di nascita"
            rules={[
              {
                required: true,
                message: "Specificare il luogo di nascita del firmatario",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="cellNumber"
            label="Cellulare"
            rules={[
              {
                required: true,
                min: 9,
                max: 12,
                // type: 'number',
                message:
                  "Specificare il numero di cellulare valido del firmatario",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                type: "email",
                message: `Specificare un indirizzo email valido del firmatario`,
              },
            ]}
          >
            <Input />
          </Form.Item>

          {props.fea && !hasSignature && (
            <React.Fragment>
              <Divider>Identificazione Firma Elettronica Avanzata</Divider>
              <Form.Item
                label="Documento identificativo"
                name="documentType"
                rules={[
                  {
                    required: true,
                    message: `Specificare il tipo del documento identificativo`,
                  },
                ]}
              >
                <Select>
                  {documentTypes.map((c) => {
                    return <Option value={c}>{c}</Option>;
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                name="documentIssuer"
                label="Documento emesso da"
                rules={[
                  {
                    required: true,
                    message: `Specificare l'emittente del documento identificativo`,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="documentNumber"
                label="Numero documento"
                rules={[
                  {
                    required: true,
                    message: `Specificare il numero di documento identificativo`,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="documentEndDate"
                label="Scadenza documento"
                rules={[
                  {
                    required: true,
                    message: "Specificare la data di scadenza del documento",
                  },
                ]}
              >
                <DatePicker placeholder="" format="DD/MM/YYYY" />
              </Form.Item>
              <Form.Item
                name="document"
                label="Scansione documento"
                rules={[
                  {
                    required: true,
                    message: `Inserire la scansione del documento`,
                  },
                ]}
              >
                <Upload
                  accept=".pdf.,.jpg,.jpeg,.jpe,.png,.tiff,.tif"
                  beforeUpload={handleBeforeUpload}
                  multiple={false}
                  maxCount={1}
                  listType="text"
                >
                  <Button icon={<UploadOutlined />}>Carica documento</Button>
                </Upload>
              </Form.Item>
            </React.Fragment>
          )}
          <Form.Item valuePropName="checked" label="Il firmatario sono io">
            <Switch
              checked={iAmSigner}
              onChange={(v) => setIAmSigner(v)}
              disabled={props.graphometric}
            />
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Firma Documento
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};
export default SignerForm;
