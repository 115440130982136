// eslint-disable-next-line
import React, { Component } from "react";
import Preview from "./preview";
import SignerForm from "./signer";
import { RouteComponentProps } from "react-router";
import "./style.scss";
import jwtDecode from "jwt-decode";
import axios from 'axios';
import { Spin, Button, Row, Col, Card, Result } from "antd";
import { Switch, Route } from "react-router-dom";
import queryString from 'query-string';
import moment from "moment";
import 'moment/locale/it';
import ISigner from "./ISigner";

moment.locale('it')
interface SignProps extends RouteComponentProps {
  setLogo: (logo: string) => void
}

class Sign extends Component<SignProps, { sign: any, step: 'preview' | 'form' | 'confirmation', spinning: boolean }> {
  constructor(props: SignProps) {
    super(props);
    this.state = { sign: null, step: 'preview', spinning: false };
  }
  async componentDidMount() {
    const qs = queryString.parse(this.props.location.search);
    const token = qs.token as string;
    if (!token) {
      this.props.history.push("/error/500", { message: "Token di firma mancante" })
      return;
    }
    let decoded;
    try {
      decoded = jwtDecode(token) as { Url: string };
      if (!decoded) {
        this.props.history.push("/error", { message: "Token di firma non valido" })
        return;
      }
    }
    catch (e) {
      this.props.history.push("/error", { message: "Token di firma non valido" });
      return;
    }
    axios.defaults.baseURL = `${decoded.Url.replace(/\/+$/, '')}/api/latest`;
    axios.interceptors.request.use(r => {
      if (r.headers) {
        r.headers.Authorization = `DigitalSign ${token}`;
        r.headers['Crm-NullValueHandling'] = 'Include';
      }
      return r;
    });
    try {
      const sign = await axios.get('DigitalSign');
      this.setState({
        ...this.state,
        sign: sign.data,
      });
      this.props.setLogo(sign.data.companyLogo);
    }
    catch (e: any) {
      switch (e.response?.status) {
        case 404:
        case 410:
          this.props.history.push("/error/404", { message: e.message })
          return;
        case 401:
        case 403:
          this.props.history.push("/error/403", { message: e.message })
          return;
        case 409:
          this.props.history.push("/error/500", { message: 'La richiesta di firma è già stata inviata, non è possibile inviarne una seconda' })
          return;
      }
      this.props.history.push("/error/500", {
        message:
          e?.exceptionMessage ?? e?.message
      })
      throw e;
    }
  }
  async handleSignerRegistered(signer: Partial<ISigner>, iAmSigner: boolean) {
    try {
      this.setState({
        ...this.state,
        sign: {
          ...this.state.sign,
          signer
        },
        spinning: true
      });
      await axios.post('DigitalSign/Assign', signer);
      const sres = await axios.post(`DigitalSign/Start?sendEmail=${!iAmSigner}`, {});
      if (sres.data) {
        window.location.href = sres.data;
      }
      else {
        this.props.history.push(`${this.props.match.url}/thanks`)
      }
    }
    catch (e: any) {
      this.props.history.push("/error/500", {
        message:
          e.response?.data?.exceptionMessage ??
          e.response?.data?.message ??
          e?.exceptionMessage ??
          e?.message
      })
      throw e;
    }
    finally {
      this.setState({
        ...this.state,
        spinning: false
      });
    }
  }
  render() {
    if (this.state.sign === null) {
      return (<div>
        <div className="text-center p-t">
          <Spin size="large" />
        </div>,
      </div>)
    }
    return (<div>
      <Spin size="large" spinning={this.state.spinning}>
        <Switch>
          <Route exact path={`${this.props.match.url}/form`}>
            <div>
              <span>
                <h3 className="text-center">
                  <div className="p-t p-b">
                    Inserisci i dati del firmatario del documento
            </div>
                </h3>
              </span>
              <div>
                <SignerForm signer={this.state.sign.signer} input={(s, im) => this.handleSignerRegistered(s, im)} graphometric={this.state.sign.graphometric} fea={this.state.sign.fea} enableSignerReassign={this.state.sign.enableSignerReassign} />
              </div>
            </div>
          </Route>
          <Route exact path={`${this.props.match.url}/thanks`}>
            <Result
              status="success"
              title="Il processo di firma è stato avviato correttamente"
              subTitle={`Il firmatario che hai precedentemente indicato riceverà una mail all'indirizzo ${this.state.sign?.signer?.email} con le istruzioni per completare la firma del documento.`}
            />
          </Route>
          <Route>
            <div>
              <span>
                <h3 className="text-center">
                  <div className="p-t p-b">
                    {this.state.sign.companyName} ha chiesto la tua firma su questo documento
            </div>
                </h3>
              </span>
              <Row justify="start">
                <Col xs={24} lg={16} xl={16} xxl={18}>
                  <div className="p-l-h p-r-h">
                    <Preview pages={this.state.sign.pages}></Preview>
                  </div>
                </Col>
                <Col xs={24} lg={8} xl={8} xxl={6}>
                  <Card className="m-r-h">
                    <div>
                      <p>
                        <b>{this.state.sign.requestBy}</b>, per conto dell'azienda <i>{this.state.sign.companyName}</i> ha richiesto la firma sul documento
                    &nbsp;<i><u>{this.state.sign.title}</u></i>.
                      </p>
                      <p>
                        Il documento può essere firmato entro il <u>{moment(this.state.sign.validityDate).format('LL')}</u>
                      </p>
                    </div>
                    <div className="text-center m-t">
                      <Button type="primary" onClick={() =>
                        this.props.history.push(`${this.props.match.url}/form?${this.props.location.search}`)
                      }>Procedi con la firma</Button>
                    </div>
                    <div>

                    </div>
                  </Card>
                </Col>
              </Row>
            </div>
          </Route>
        </Switch>
      </Spin>
    </div>);
  }
};

export default Sign;
