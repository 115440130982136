// eslint-disable-next-line
import React, { Component } from "react";
import { Layout, Row, Col, Result } from "antd";
import { SmileOutlined } from '@ant-design/icons';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Sign from "./sign";
import Error from "./error";
import "./App.less"
import "./App.scss";

const { Header, Content, Footer } = Layout;
class App extends Component<{}, { logo: string }> {
  constructor(params: {}) {
    super(params);
    this.state = { logo: '' };
  }

  render() {
    const handleSetLogo = (logo: string) => {
      this.setState({
        ...this.state,
        logo
      });
    }
    return (<Router>
      <Layout className="layout">
        <Header>
          <Row justify="center">
            <Col xs={23} lg={22} xl={18} xxl={16}>
              <div className="logo">
                {
                  this.state.logo && (
                    <div>
                      <img src={this.state.logo} alt="logo" />
                    </div>)
                }
              </div>
            </Col>
          </Row>
        </Header>
        <Content style={{ padding: "0 5px" }}>
          <Row>
            <Col xs={23} lg={22} xl={18} xxl={16} className="site-layout-content">
              <div>
                <Switch>
                  <Route path="/sign"
                    render={(props) => (
                      <Sign {...props} setLogo={handleSetLogo} />
                    )}>
                  </Route>
                  <Route path="/error/:status*" component={Error}>
                  </Route>
                  <Route path="/">
                    <Result
                      icon={<SmileOutlined />}
                      title="TeamSystem CRM DigitalSign Landing"
                    />
                  </Route>
                </Switch>
              </div>
            </Col>
          </Row>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          TeamSystem CRM ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Router>);
  }
}

export default App;
